import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  drivers: [],
  driver: null,
  companyCarList: [],
};

const slice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET drivers
    getDriversSuccess(state, action) {
      state.isLoading = false;
      state.drivers = action.payload;
    },

    // GET driver
    getDriverSuccess(state, action) {
      state.isLoading = false;
      state.driver = action.payload;
    },
    // GET Company car
    getCompanyCarSuccess(state, action) {
      state.isLoading = false;
      state.companyCarList = action.payload;
    },
    //  Create driver
    createDriverSuccess(state, action) {
      state.isLoading = false;
      const newDriver = action.payload;
      state.drivers = [...state.drivers, newDriver];
    },
    // Update driver
    updateDriverSuccess(state, action) {
      state.isLoading = false;
      state.drivers = state.drivers.map((driver) => {
        if (driver.id === action.payload.id) {
          return action.payload;
        }
        return driver;
      });
    },
    // Delete driver
    deleteDriverSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getDriversSuccess,
  getDriverSuccess,
  createDriverSuccess,
  updateDriverSuccess,
  deleteDriverSuccess,
} = slice.actions;

// ----------------------------------------------------------------------
/*eslint-disable*/
export function getDrivers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('driver/get-all');
      dispatch(slice.actions.getDriversSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDriver(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`driver/get-by-id/${id}`);
      dispatch(slice.actions.getDriverSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createDriver(driver) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('driver/add', driver);
      dispatch(slice.actions.createDriverSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateDriver(id, driver) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`driver/${id}`, driver);
      dispatch(slice.actions.updateDriverSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCompanyCar(id, driver) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`car/find-by-company/${id}`);
      dispatch(slice.actions.getCompanyCarSuccess(response.data));
      return response.status;
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
