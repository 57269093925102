import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  contractors: [],
  contractor: null,
};

const slice = createSlice({
  name: 'contractor',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET contractors
    getContractorsSuccess(state, action) {
      state.isLoading = false;
      state.contractors = action.payload;
    },

    // GET contractor
    getContractorSuccess(state, action) {
      state.isLoading = false;
      state.contractor = action.payload;
    },
    //  Create contractor
    createContractorSuccess(state, action) {
      state.isLoading = false;
      const newContractor = action.payload;
      state.contractors = [...state.contractors, newContractor];
    },
    // Update contractor
    updateContractorSuccess(state, action) {
      state.isLoading = false;
      state.contractors = state.contractors.map((contractor) => {
        if (contractor.id === action.payload.id) {
          return action.payload;
        }
        return contractor;
      });
    },
    // Delete contractor
    deleteContractorSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getContractorsSuccess,
  getContractorSuccess,
  createContractorSuccess,
  updateContractorSuccess,
  deleteContractorSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getContractors() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('contractor/get-all');
      dispatch(slice.actions.getContractorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContractor(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`contractor/get-by-id/${id}`);
      dispatch(slice.actions.getContractorSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createContractor(contractor) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('contractor/add', contractor);
      dispatch(slice.actions.createContractorSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateContractor(id, contractor) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`contractor/${id}`, contractor);
      dispatch(slice.actions.updateContractorSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
