// @mui
import {  frFR } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const Franch = {
  label: 'French',
  value: 'fr',
  systemValue: frFR,
  icon: '/assets/icons/flags/ic_flag_fr.svg',
};

export const defaultLang = Franch; // Franch
