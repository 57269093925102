import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import driverReducer from './slices/driver';
import contractorReducer from './slices/contractor';
import conversationReducer from './slices/conversation';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const dispatchTabsPersistConfig = {
  key: 'dispatchTabs',
  storage,
  keyPrefix: 'redux-',
  // TODO: remove this when we have proper storage
  whitelist: [],
};

const rootReducer = combineReducers({
  driver: driverReducer,
  contractor: contractorReducer,
  conversation: conversationReducer,
});

export default rootReducer;
