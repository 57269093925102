// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  taxis: icon('ic_taxis'),
  entites: icon('ic_entites'),
  resa: icon('ic_resa'),
  compta: icon('ic_compta'),
  etats: icon('ic_etats'),
  outils: icon('ic_outils'),
  statistiques: icon('ic_statistiques'),
  administration: icon('ic_administration'),
  ads: icon('ic_blog'),
  car: icon('ic_blog'),
  driver: icon('ic_blog'),
  driverStatus: icon('ic_cart'),
  dashboard: icon('ic_dashboard'),
  clients: icon('ic_clients'),
  staff: icon('ic_staff'),
  alerts: icon('ic_alerts'),
  affairs: icon('ic_affairs'),
  codePromo: icon('ic_clientCodePromo'),
  parrainage: icon('ic_parrainage'),
  // ----
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.analytics, icon: ICONS.statistiques },
      { title: 'chat', path: PATH_DASHBOARD.general.chat, icon: ICONS.chat },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     // {
  //     //   title: 'user',
  //     //   path: PATH_DASHBOARD.user.root,
  //     //   icon: ICONS.user,
  //     //   children: [
  //     //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //     //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //     //     { title: 'list', path: PATH_DASHBOARD.user.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.user.new },
  //     //     { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //     //     { title: 'account', path: PATH_DASHBOARD.user.account },
  //     //   ],
  //     // },
  //     // E-COMMERCE
  //     // {
  //     //   title: 'ecommerce',
  //     //   path: PATH_DASHBOARD.eCommerce.root,
  //     //   icon: ICONS.cart,
  //     //   children: [
  //     //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //     //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //     //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //     //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //     //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //     //   ],
  //     // },
  //     // INVOICE
  //     // {
  //     //   title: 'invoice',
  //     //   path: PATH_DASHBOARD.invoice.root,
  //     //   icon: ICONS.invoice,
  //     //   children: [
  //     //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //     //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //     //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //     //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //     //   ],
  //     // },
  //     // BLOG
  //     // {
  //     //   title: 'blog',
  //     //   path: PATH_DASHBOARD.blog.root,
  //     //   icon: ICONS.blog,
  //     //   children: [
  //     //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //     //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //     //     { title: 'create', path: PATH_DASHBOARD.blog.new },
  //     //   ],
  //     // },
  //     // {
  //     //   title: 'File manager',
  //     //   path: PATH_DASHBOARD.fileManager,
  //     //   icon: ICONS.folder,
  //     // },
  //   ],
  // },
  // TODO:New MENU

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //     },
  //     {
  //       title: 'calendar',
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.lock,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //
  //     {
  //       title: 'item_disabled',
  //       path: '#disabled',
  //       icon: ICONS.disabled,
  //       disabled: true,
  //     },

  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.label,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     {
  //       title: 'item_external_link',
  //       path: 'https://www.google.com/',
  //       icon: ICONS.external,
  //     },
  //     {
  //       title: 'blank',
  //       path: PATH_DASHBOARD.blank,
  //       icon: ICONS.blank,
  //     },
  //   ],
  // },
];

export default navConfig;
