// import sum from 'lodash/sum';
// import uniq from 'lodash/uniq';
// import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import { dataTaches } from '../../assets/data';
// import axios from '../../utils/axios';
import { initializeApp } from 'firebase/app';

// import {
//   getAuth,
//   signOut,
//   signInWithPopup,
//   onAuthStateChanged,
//   GoogleAuthProvider,
//   GithubAuthProvider,
//   TwitterAuthProvider,
//   signInWithEmailAndPassword,
//   createUserWithEmailAndPassword,
// } from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  //   getDoc,
  setDoc,
  addDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
  where,
  query,
  deleteDoc,
  orderBy,
} from 'firebase/firestore';
// config
import { FIREBASE_API } from '../../config-global';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  posts: [],
  user: null,
  postsProfil: [],
  profileUser: {},
  isLiked: false,
  likesCount: null,
  postUser: null,
  conversations: [],
  messages: [],
};
const firebaseApp = initializeApp(FIREBASE_API);
const DB = getFirestore(firebaseApp);

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCT
    getConversationsSuccess(state, action) {
      state.isLoading = false;
      state.conversations = action.payload;
    },
    getOneConversationSuccess(state, action) {
      state.isLoading = false;
      state.messages = action.payload;
    },
    createMessageSuccess(state, action) {
      state.isLoading = false;
    },
    createPostSuccess(state, action) {
      //   const newEvent = action.payload;
      state.isLoading = false;
      //   state.events = [...state.events, newEvent];
    },
    getUserSuccess(state, action) {
      //   const newEvent = action.payload;
      state.isLoading = false;
      state.user = action.payload;
      //   state.events = [...state.events, newEvent];
    },
    getPostsByEmailSuccess(state, action) {
      state.isLoading = false;
      state.postsProfil = action.payload;
    },
    getUserByIdSuccess(state, action) {
      //   const newEvent = action.payload;
      state.isLoading = false;
      state.profileUser = action.payload;
      //   state.events = [...state.events, newEvent];
    },
    getLikesSuccess(state, action) {
      state.isLoading = false;
      state.isLiked = action.payload.liked;
      state.likesCount = action.payload.count;
    },
    getPostUserSuccess(state, action) {
      state.isLoading = false;
      state.postUser = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { createActivite, rappelerPlusTard, createPaiement } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getConversations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, 'conversations');
      //   const object = {
      //     post: data,
      //   };
      //   const res = await addDoc(dbRef, object);
      onSnapshot(dbRef, (res) => {
        /* eslint-disable arrow-body-style */
        const data = res.docs.map((docs) => {
          return { ...docs.data(), id: docs.id };
        });
        dispatch(slice.actions.getConversationsSuccess(data));
      });
      // const newtache = dataTaches.data.filter((item) => item.uuid === uuid);
      // const response = await axios.get('/api/products/product', {
      //   params: { id },
      // });
      //   dispatch(slice.actions.getPostsSuccess(data));
      // dispatch(slice.actions.getTacheSuccess(newtache));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// get one conversation
export function getOneConversation(conversationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, `conversations/${conversationId}/messages`);
      const orderedQuery = query(dbRef, orderBy('created_at'));

      onSnapshot(orderedQuery, (res) => {
        /* eslint-disable arrow-body-style */
        const data = res.docs.map((docs) => {
          return { ...docs.data(), id: docs.id };
        });

        dispatch(slice.actions.getOneConversationSuccess(data));
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateConversation(conversationId, payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, 'conversations');
      const postToUpdate = doc(dbRef, conversationId);

      await updateDoc(postToUpdate, payload);
      // dispatch(slice.actions.createPostSuccess(res.id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add message
export function addMessage(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const dbRef = collection(DB, 'messages');
      const dbRef = collection(DB, `conversations/${data.conversationId}/messages`);

      const object = {
        created_at: serverTimestamp(),
        content: data.message,
        sender_id: data.sender_id,
      };
      const res = await addDoc(dbRef, object);
      dispatch(slice.actions.createMessageSuccess(res.id));

      // onSnapshot(dbRef, (res) => {
      //   const data = res.docs.map((docs) => {
      //     return { ...docs.data(), id: docs.id };
      //   });
      //   dispatch(slice.actions.getPostsSuccess(data));
      // });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPostsByUser(emailToRetrieve) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, 'posts');
      const qu = query(dbRef, where('email', '==', emailToRetrieve));
      onSnapshot(qu, (res) => {
        const data = res.docs.map((docs) => {
          return { ...docs.data(), id: docs.id };
        });
        dispatch(slice.actions.getPostsByEmailSuccess(data));
      });
      // const operation = where("email", "==", emailTo);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// create post
export function addPost(newPost) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, 'posts');
      const object = {
        createdAt: serverTimestamp(),
        post: newPost.post,
        name: newPost.name,
        email: newPost.email,
        userID: newPost.userID,
      };
      const res = await addDoc(dbRef, object);
      dispatch(slice.actions.createPostSuccess(res.id));

      // onSnapshot(dbRef, (res) => {
      //   const data = res.docs.map((docs) => {
      //     return { ...docs.data(), id: docs.id };
      //   });
      //   dispatch(slice.actions.getPostsSuccess(data));
      // });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// profile

export function editProfile(userId, payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, 'users');
      const userToUpdate = doc(dbRef, userId);

      // const object = {
      //   createdAt: serverTimestamp(),
      //   post: newPost.post,
      //   name: newPost.name,
      //   email: newPost.email,
      // };
      await updateDoc(userToUpdate, payload);
      // const res = await addDoc(dbRef, object);
      // dispatch(slice.actions.createPostSuccess(res.id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// getUser
export function getUser(userID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const userRef = doc(DB, 'users', userID);

      onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          // Get the updated user data
          const updatedUserData = docSnapshot.data();
          // setUserData(updatedUserData);
          dispatch(slice.actions.getUserSuccess(updatedUserData));
        }
      });

      // onSnapshot(dbRef, (res) => {
      //   const data = res.docs.map((docs) => {
      //     return { ...docs.data(), id: docs.id };
      //   });
      //   dispatch(slice.actions.getPostsSuccess(data));
      // });
      // const newtache = dataTaches.data.filter((item) => item.uuid === uuid);
      // const response = await axios.get('/api/products/product', {
      //   params: { id },
      // });
      //   dispatch(slice.actions.getPostsSuccess(data));
      // dispatch(slice.actions.getTacheSuccess(newtache));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// getUserById
export function getUserById(userID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const userRef = doc(DB, 'users', userID);

      onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          // Get the updated user data
          const userData = docSnapshot.data();
          // setUserData(updatedUserData);
          dispatch(slice.actions.getUserByIdSuccess(userData));
        }
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addLikePost(userID, postID, isLiked) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, 'likes');
      const docToLike = doc(dbRef, `${userID}_${postID}`);
      if (isLiked) {
        await deleteDoc(docToLike);
        // dispatch(slice.actions.createPostSuccess());
      } else {
        await setDoc(docToLike, { userID, postID });
        // dispatch(slice.actions.createPostSuccess());
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// get likes
export function getLikes(userID, postID, setLiked, setLikes) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const likesRef = collection(DB, 'likes');
      const likesQuery = query(likesRef, where('postID', '==', postID));
      onSnapshot(likesQuery, (res) => {
        const likesData = res.docs.map((docs) => docs.data());

        const isLiked = likesData.some((like) => like.userID === userID);
        setLikes(likesData.length);
        setLiked(isLiked);
        // dispatch(
        //   slice.actions.getLikesSuccess({
        //     count: likesData.length,
        //     liked: isLiked,
        //   })
        // );
        // dispatch(slice.actions.getPostsSuccess(data));
      });
      // onSnapshot(likesRef, (docSnapshot) => {
      //   if (docSnapshot.exists()) {
      //     // Get the updated user data
      //     const likesData = docSnapshot.data();
      //     // setUserData(updatedUserData);
      //     const isLiked = likesData.some((like) => like.id === userID);
      //     dispatch(
      //       slice.actions.getLikesSuccess({
      //         count: likesData.length,
      //         Liked: isLiked,
      //       })
      //     );
      //   }
      // });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// add comment
export function addCommentPost(userID, postID, message, name) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, 'comments');
      /* eslint-disable object-shorthand */
      const object = {
        createdAt: serverTimestamp(),
        message: message,
        postID: postID,
        userID: userID,
        name: name,
      };
      const res = await addDoc(dbRef, object);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getComments(postID, setComments) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const commentsRef = collection(DB, 'comments');
      const commentsQuery = query(commentsRef, where('postID', '==', postID));
      onSnapshot(commentsQuery, (res) => {
        const commentsData = res.docs.map((docs) => {
          return { ...docs.data(), id: docs.id };
        });

        setComments(commentsData);
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// getUser
export function getPostUser(userID, setPostUser) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const userRef = doc(DB, 'users', userID);

      onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          // Get the updated user data
          const userData = docSnapshot.data();
          // setUserData(updatedUserData);
          setPostUser(userData);
          // dispatch(slice.actions.getPostUserSuccess(userData));
        }
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// delete post
export function deletePost(postID) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, 'posts');
      const docToDelete = doc(dbRef, postID);
      await deleteDoc(docToDelete);
      // dispatch(slice.actions.createPostSuccess());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// update post
export function updatePost(postID, payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const dbRef = collection(DB, 'posts');
      const postToUpdate = doc(dbRef, postID);

      await updateDoc(postToUpdate, payload);
      // dispatch(slice.actions.createPostSuccess(res.id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
