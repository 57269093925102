import { Navigate, useRoutes } from 'react-router-dom';
// auth
import { AuthProvider } from '../auth/FirebaseContext';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';

import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  // RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  //* app: General
  GeneralAnalyticsPage,
  UserCardsPage,
  UserAccountPage,
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  ChatPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },

        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // app
    {
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'analytics', element: <GeneralAnalyticsPage /> },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/app/user/profile" replace />, index: true },
            // { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },

            { path: 'account', element: <UserAccountPage /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '/',
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      ),
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { path: 'pricing', element: <PricingPage /> },
    //     { path: 'payment', element: <PaymentPage /> },
    //   ],
    // },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
